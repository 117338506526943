.LogoContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border-radius: 1rem;
  border: none;
}

.LogoImage {
  width: 2rem;
  height: 2rem;
}

.LogoContainer h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}
