.ChatPageContainer {
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.ChatPageSideBar {
  width: 22rem; /* Full width when expanded */
  transition:
    width 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  opacity: 1;
}

.ChatPageSideBar.collapsed {
  width: 0; /* Sidebar shrinks to 0 when collapsed */
  opacity: 0; /* Fades out */
  overflow: hidden; /* Hides content when collapsed */
}

.primaryChatContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  transition: margin-left 0.3s ease-in-out;
}

.ChatPageToggleButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  padding: 1rem;
  cursor: pointer;
  color: #000;
}

.ChatPageMainWrapper {
  display: flex;
  flex: 1;
  padding: 1rem 1rem 0 1rem;
}

.ChatPageSourceColumn {
  flex-direction: column;
  flex-basis: 19rem;
  /* max-width: 20vw; */
  margin: 0rem 1rem 0rem 0rem;
  max-height: 80vh; /*or whatever you like */
  overflow-y: auto;
  scrollbar-gutter: stable;
  overflow-x: hidden;
}

.ChatPageSourceErrorContainer {
  padding: 1rem;
}

.ChatPageChatArea {
  flex: 3;
}

.responseAreaWrapper {
  display: flex;
  gap: 16px;
}

.ChatPageResponse {
  flex: 1;
  overflow-y: auto;
}

.ChatPageBotResponse {
  color: #000;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 8px 18px;
  text-align: start;
  border-radius: 20px;
  background: #ededed;
  width: 100%;
}

.ChatSearchContainer {
  padding: 0 3rem 0rem 3rem;
  text-align: center;
  position: sticky;
  bottom: 0;
  z-index: 10;
  background-color: white;
}

.ChatPageQuery {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0px;
}

.ChatPageConfirmButton {
  background-color: #277ee5;
  border-radius: 50%;
  padding: 8px;
  color: #ffffff;
  font-size: 22px;
  cursor: pointer;
}

.ChatPageSetting {
  position: absolute;
  right: 1%;
  margin-top: 0.5%;
  display: flex;
  padding: 16px 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  text-align: start;
  background: #fff;
  box-shadow: 0px 4px 25px 0px #959595;
  cursor: pointer;
}

.ChatPageSetting p {
  margin: 0;
  padding-bottom: 8px;
  font-weight: 600;
}

.ChatSideBarHistoryHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ChatSideBarHistoryRefreshButton {
  cursor: pointer;
}

.ChatSideBarNewChatButton {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 25px;
  border: 1px solid #277ee5;
  background: #fff;
  color: #277ee5;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.48px;
}

.new-chat-btn span {
  margin-left: 10px;
  /* font-weight: bold; */
}

.RecentHistoryHeaderText {
  margin-bottom: 1rem;
  font-weight: bold;
}

.ChatListContainer {
  display: flex;
  /* overflow: hidden; */
}

.chat-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden; /*Prevent content from spilling out*/
  text-overflow: ellipsis; /* Add ellipsis to indicate truncated text */
  white-space: nowrap; /* Prevent wrapping */
}

.chat-list li {
  align-items: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
  cursor: pointer;
  text-align: left;
}

.chat-list li:hover {
  background-color: #cccccc;
  border-radius: 8px;
}

.chat-list li span {
  margin-left: 10px;
}

.show-more {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.show-more span {
  margin-left: 10px;
}

.show-more-btn {
  text-decoration: none;
  color: #000;
}

.ChatScrollArea {
  max-height: 80vh; /*or whatever you like */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chat-message {
  padding: 8px;
  border-radius: 6px;
  text-align: left;
  font-size: 20px; /* Sets font size */
  line-height: 1.5; /* Adjusts line spacing */
  max-width: 60ch; /* Limits character length per line */
}

.chat-message.user {
  background-color: #d0f0ff;
  align-self: flex-end;
}

.chat-message.system {
  background-color: #ededed;
  align-self: flex-start;
}
