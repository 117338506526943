.TranscriptsPageEditPage {
  padding: 32px 15px;
}

.TranscriptPageTableContainer {
  display: grid;
  margin: 1rem;
  place-items: center;
}

.TranscriptsPageBackButton {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 18px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  background-color: #c7c7c7;
  color: black;
}

.TranscriptsPageTranscriptTitle,
.TranscriptsPageTranscriptURL {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  /* color: black; */
}

.TranscriptsPageTranscriptURL {
  color: #2196f3;
}

.TranscriptsPageAddStudyButton {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 18px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  background-color: #2196f3;
  color: white;
}

.TranscriptPageTablePagingButtonRow {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

.TranscriptPageTablePagingButton {
  display: inline-block;
  margin: 1rem;
  background-color: #2196f3; /* Background color */
  color: white; /* Text color */
  cursor: pointer; /* Shows pointer cursor on hover */
}
