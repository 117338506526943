@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.UploadSubmitButton {
}
.UploadFormRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 0 0 0.5rem 0;
}

.UploadFormSubmitRow {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.UploadTranscriptInput {
  display: none;
}

.UploadTranscriptFileLabel {
  display: inline-block;
  padding: 8px 16px;
  margin: 1rem;
  background-color: #2196f3;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: normal;
}

.UploadFormButton {
  display: inline-block;
  padding: 8px 16px;
  margin: 1rem;
  background-color: #2196f3;
  color: white;
  border-radius: 0.25rem;
  border-width: 0;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}
