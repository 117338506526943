.TabFormContainer {
  font-family: Arial, sans-serif;
  max-width: 50rem;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.TabFormTabContainer {
  display: flex;
  justify-content: start;
  margin-bottom: 1rem;
}

.TabFormTab {
  flex: 1;
  padding: 0.5rem;
  max-width: 10rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.TabFormTab:not(:last-child) {
  margin-right: 5px;
}

.TabFormTab.TabFormActiveTab {
  background-color: #2196f3;
  color: white;
  border-bottom: 1px solid white;
}

.TabFormContent {
  padding: 1rem;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: white;
}
