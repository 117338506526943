.FeedBackButton {
  position: fixed; /* Fixes the button to a specific position relative to the viewport */
  bottom: 11rem; /* Distance from the bottom of the viewport */
  right: 1rem; /* Distance from the right of the viewport */
  width: 4rem; /* Make the button square by setting width and height equal */
  height: 4rem;
  background-color: #2196f3; /* Blue background */
  color: white; /* White icon/text color */
  border: none; /* Remove default border */
  border-radius: 25%; /* Makes the square have rounded corners (fully rounded) */
  cursor: pointer; /* Pointer cursor on hover */
  display: flex; /* Flexbox for centering icon/text */
  justify-content: center; /* Horizontally center icon/text */
  align-items: center; /* Vertically center icon/text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for emphasis */
  font-size: 2rem; /* Font size for content inside the button */
  z-index: 100;
}

.FeedBackButton:hover {
  background-color: #1976d2; /* Slightly darker blue on hover */
}
