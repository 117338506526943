.MainTopBarButton {
  background-color: white;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.4rem;
  padding: 0.25rem 0.5rem;
  transition: background-color 0.2s ease-in-out;
}

.MainTopBarButton:hover {
  cursor: pointer;
  background-color: rgb(245, 190, 100);
}

.MainTopBarHighlightButton {
  background-color: rgb(245, 190, 100);
}

.MainTopBarLogoRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.MainTopBarUserButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem 0 1rem;
}

.MainTopBarLoginButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  background-color: rgb(245, 190, 100);
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.4rem;
  padding: 0.35rem 1rem;
  cursor: pointer;
}

.MainTopBarSignUpButton {
  border-color: rgb(245, 190, 100);
  border-style: solid;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  padding: 0.35rem 1rem;
  cursor: pointer;
}

.MainTopBarSignOutButton {
  border-color: rgb(245, 190, 100);
  border-style: solid;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  padding: 0.35rem 1rem;
  cursor: pointer;
}
