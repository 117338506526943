.SourceCardContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.SourceCardLinkText {
  display: block; /* Keeps it from affecting inline elements */
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.parentReferenceCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.SourceCardRelevancyContainer {
  display: flex;
  justify-content: end;
  align-items: center;
}

.SourceCardRelevancyScore {
  margin: 0.25rem;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.SourceCardRelevanceBarContainer {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
}

.SourceCardRelevanceBar {
  height: 100%;
  background-color: #277ee5;
  transition: width 0.3s ease-in-out;
}

.SourceCardEmbedContainer {
  width: 100%;
  height: auto; /* Allow natural height */
  display: flex;
  justify-content: center;
}

.SourceCardEmbedContainer iframe {
  width: 100%;
  height: 169px; /* Ensures proper aspect ratio */
}
