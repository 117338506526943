.BugReportFormContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.BugReportFormRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
}

.BugReportFormCenteredRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BugReportFormLabel {
  display: flex;
  align-items: left;
  justify-content: left;
  padding-right: 0.5rem;
}

.BugReportFormSpacer {
  padding: 0.5rem;
}
