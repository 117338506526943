.HoveringCardContainer {
  background: #fff;
  box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);
  margin: 0 0 1rem 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.HoveringCardRounded {
  border-radius: 1rem;
}

.HoveringCardBordered {
  border-width: 1px;
  border-color: #e5e5e5;
  border-style: solid;
}
