.AdminSideBarContentList {
  list-style: none;
  padding: 1rem;
  margin: 0;
  overflow: hidden; /*Prevent content from spilling out*/
  text-overflow: ellipsis; /* Add ellipsis to indicate truncated text */
  white-space: nowrap; /* Prevent wrapping */
}

.AdminSideBarContentList li {
  align-items: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
  cursor: pointer;
  text-align: left;
  font-size: 1.5rem;
}
.AdminSideBarContentList li {
  border-top: 1px solid #ccc; /* Add a top border to all list items */
}

.AdminSideBarContentList li:is(:last-child) {
  border-bottom: 1px solid #ccc; /* Add a top border to all list items */
}
