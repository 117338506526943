.InputField {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: vertical; /* Allow vertical resizing */
  overflow-y: auto;
  background: var(--CoolGray-10, #f2f4f8);
  flex: 1;
}

.InputContainer {
  display: flex;
  width: 100%;
}
