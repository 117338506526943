.PrimaryFooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PrimaryFooterContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 1rem 7rem;
}

.PrimaryFooterContainerDisclaimerColumn {
  flex: 4;
  flex-direction: column;
  line-height: 2rem;
}

.PrimaryFooterContainerDisclaimerText {
  font-size: 1.25rem;
  color: gray;
  padding-left: 1rem;
}

.PrimaryFooterContainerNavigationColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  margin: 1rem;
}

.PrimaryFooterNavigationText {
  font-weight: bold;
  cursor: pointer;
}

.PrimaryFooterContainerContactUsColumn {
  flex: 2;
  flex-direction: column;
}

.PrimaryFooterSpacer {
  flex: 1;
}

.PrimaryFooterBreakLine {
  border: none; /* Remove default border */
  width: 80%;
  height: 1px; /* Thickness of the line */
  background-color: #e0e0e0; /* Color of the line */
  margin: 1rem 0; /* Add spacing above and below */
  opacity: 0.8; /* Slight transparency (optional) */
}
