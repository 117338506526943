.DropDown-container {
  display: flex;
  align-items: center;
}

/* .DropDown-container select:hover {
  background-color: #666666;
} */

.DropDown-label {
  margin-right: 0.5rem; /* Space between label and dropdown */
  font-size: 1.5rem;
  font-weight: bold;
  color: black; /* Assuming you want the label text to be white */
}

.DropDown-select {
  display: block;
  width: 100%;
  /* text-align-last: center; */
  /* background-color: black; */
  /* color: white; */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  /* appearance: none; */
  font-weight: 500;
  font-size: 1rem;
  border: 1px solid #333333;
  /* margin: 0rem 0.5rem; */
  /* background-image: none; */
  background: var(--CoolGray-10, #f2f4f8);
  border: 1px solid #ccc;
}

/* For styling the focus state */
.DropDown-select:focus {
  /* outline: none; */
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}
