.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  flex-direction: column;
}

.signin-box {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 400px;
}

.signin-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.signin-error {
  color: red;
  margin-bottom: 15px;
}

.signin-input {
  width: 100%; /* Make input width 100% */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  padding: 12px 15px; /* Adjust padding for better alignment */
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.signin-button {
  background-color: #277ee5;
  color: white;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Button width 100% for consistency */
  margin-top: 10px;
}

.signin-button:hover {
  background-color: #277ee5;
}

.signin-terms {
  font-size: 16px;
  margin-top: 20px;
  color: #666;
}

.signin-terms a {
  color: #277ee5;
  text-decoration: none;
}

.signin-terms a:hover {
  text-decoration: underline;
}

.logoName {
  margin-bottom: 35px;
}
