/* Full-screen overlay */
.LargeFormPanelOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Form panel */
.LargeFormPanel {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  width: 40rem;
  max-width: 70rem;
  position: relative;
}

/* Form header */
.LargeFormPanelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.25rem;
}

/* Title in the center */
.LargeFormPanelTitle {
  flex: 1;
  text-align: center;
  margin: 0;
  font-size: 2rem;
  font-weight: normal;
}

/* Close button in the top-right */
.LargeFormPanelCloseButton {
  position: relative;
  flex: none;
  background: none;
  border: none;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  padding: 1rem;
  line-height: 1;
}

.LargeFormPanelCloseButton:hover {
  color: red;
}

/* Form content */
.LargeFormPanelContent {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  max-height: 40rem;
  overflow-y: scroll;
}
