.expanding-textarea-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.expanding-textarea-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.expanding-textarea {
  line-height: 1rem; /* Adjust to your design */
  overflow-y: auto; /* Allow scrolling when the max height is reached */
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}
