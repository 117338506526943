/* Container styling */
.create-study-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  max-width: 30%;
}

.keyword-add {
  display: flex;
}

.keyword-add button {
  width: fit-content;
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
}

/* Label styling */
.form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #333;
}

/* Input styling */
.form-input,
.keyword-input {
  width: calc(100% - 20px);
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  margin-top: 5px;
  border-radius: 50px;
}

/* Keyword list styling */
.keyword-list {
  list-style-type: none;
  padding-left: 0;
  margin: 10px 0;
}

.keyword-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
  align-items: center;
}

/* Button styling */
.remove-keyword-btn,
.add-keyword-btn {
  background-color: #2196f3;
  color: white;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 5px;
}

.remove-keyword-btn {
  margin: 0;
}

.remove-keyword-btn:hover,
.add-keyword-btn:hover {
  background-color: #1976d2;
}

/* Submit button styling */
.submit-btn {
  background-color: #2196f3;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  font-size: 16px;
  margin-top: 5px;
}

.submit-btn:hover {
  background-color: #1976d2;
}

.backButton {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 18px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  background-color: #c7c7c7;
  color: black;

  margin-bottom: 15px;
}
