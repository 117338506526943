.HomePageContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure splashes don’t overflow the container */
}

.HomePageSplashGradient {
  position: absolute;
  width: 35rem;
  height: 35rem;
  background: radial-gradient(
    circle,
    rgba(252, 101, 52, 0.2) 0%,
    rgba(255, 0, 150, 0) 70%
  );
  z-index: -1;
  mix-blend-mode: screen;
}

.HomePageSplashGradient.one {
  top: -30vh;
  left: -15vw;
}

.HomePageSplashGradient.two {
  bottom: 20vh;
  right: -15vw;
}

.HomePageContentContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.HomePageHeroRow {
  display: flex; /* Align items in a row */
  justify-content: space-between; /* Distribute columns */
  padding: 3rem 1rem 5rem 1rem; /* Add padding to the row */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.HomePageHeroTextColumn {
  display: flex;
  flex-direction: column;
  flex: 6; /* Columns take up equal space */
  margin: 0 0.5rem; /* Add spacing between columns */
  background-color: transparent; /* Example background color */
  padding: 1rem; /* Inner padding for columns */
  box-sizing: border-box;
  justify-content: center;
}
.HomePageHeroSpacer {
  flex: 1;
}

.HomePageHeroSectionText {
  font-size: 3rem;
  font-weight: bold;
  line-height: 4rem;
}
.HomePageHeroParagraphText {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  color: grey;
}

.HomePageHeroButton {
  border-color: transparent;
  background-color: rgb(245, 190, 100);
  font-size: 1rem;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  padding: 0.8rem 2.5rem;
  max-width: fit-content;
  font-weight: bold;
  cursor: pointer;
}

.HomePageHeroImageColumn {
  position: relative;
  flex: 8; /* Columns take up equal space */
  margin: 0 0.5rem; /* Add spacing between columns */
  background-color: transparent; /* Example background color */
  padding: 1rem; /* Inner padding for columns */
  box-sizing: border-box;
  height: 35rem;
  z-index: -2;
}

.HomePageHeroImageTextOneContainer {
  position: relative;
  display: flex;
  padding: 1rem 2rem 1rem 2rem;
  justify-content: center;
  align-items: center;
  left: 24rem;
  background-color: white;
  border-radius: 0.5rem;
  max-width: 10rem;
  box-shadow: 0.1rem 0.15rem 1rem 0rem rgba(149, 149, 149, 0.5);
}

.HomePageHeroImageTextOneContainer::after {
  content: '';
  position: absolute;
  bottom: -20px; /* Position the tail below the bubble */
  left: 20px; /* Adjust this to control tail location */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 0; /* Creates the triangular tail */
  border-color: #ffffff transparent transparent transparent; /* Match bubble color */
}

.HomePageHeroImageTextTwoContainer {
  position: relative;
  display: flex;
  padding: 1rem 2rem 1rem 2rem;
  justify-content: center;
  align-items: center;
  left: 20rem;
  background-color: white;
  border-radius: 0.5rem;
  max-width: 15rem;
  box-shadow: 0.1rem 0.15rem 1rem 0rem rgba(149, 149, 149, 0.5);
}

.HomePageHeroImageTextTwoContainer::after {
  content: '';
  position: absolute;
  top: 25%; /* Center vertically on the right side */
  right: -20px; /* Position the tail to the right of the bubble */
  transform: rotate(100deg); /* Center vertically and rotate */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 10px 25px; /* Creates a triangular tail */
  border-color: transparent transparent transparent #ffffff;
}

.HomePageHeroImageText {
  justify-content: center;
  align-items: center;
  margin: 0;
  font-weight: 500;
}

.HomePageHeroImageImageContainer {
  position: relative;
  background-color: white;
  padding: 0rem 3rem 0rem 3rem;
  margin: 0rem 0rem 2rem 0rem;
  border-radius: 1rem;
  width: auto;
  height: auto;
  box-shadow: 0.1rem 0.15rem 1rem 0rem rgba(149, 149, 149, 0.5);
}

.HomePageHeroImageCapsule {
  position: absolute;
  right: 2rem;
  top: 23rem;
}

.HomePageHeroImageBox {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 65%;
  margin-right: 3rem;
  border-radius: 3rem;
  background-color: rgb(255, 227, 181);
  z-index: -1;
}

.HomePageHowItWorksRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.HomePageHowItWorksCardContainer {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.HomePageCardAccentRow {
  position: relative;
  display: flex;
  justify-content: end;
  align-items: end;
  top: -1rem;
  right: -1rem;
}

.HomePageAngledSquareContainer {
  display: flex;
  rotate: -3deg;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 2rem;
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  justify-content: start;
  align-items: end;
}

.HomePageHowIconFiles {
  translate: -0.5rem;
  rotate: 3deg;
}
.HomePageHowIconIdea {
  transform: translateY(0.2rem);
  rotate: 3deg;
}
.HomePageHowIconSearch {
  translate: -0.5rem;
  rotate: 3deg;
  width: 2.75rem;
}
.HomePageHowIconQuestion {
  translate: -0.5rem;
  transform: translateY(0.25rem);
  rotate: 3deg;
}

.HomePageHowTextContainer {
  margin: 0rem 1rem 2rem 1rem;
  width: 14rem;
  height: 5rem;
}

.HomePageHowDescriptionText {
  padding: 1rem 0rem 0rem 0rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.HomePageJoinSectionText {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem;
}

.HomePageJoinRow {
  display: flex;
  flex-direction: row;
  background-color: rgb(245, 190, 100);
  border-radius: 1rem;
  margin: 5rem 0rem 1rem 0rem;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  box-shadow: 0.2rem 0.3rem 0.5rem 0rem rgba(149, 149, 149, 0.5);
}

.HomePageJoinText {
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem;
}

.HomePageJoinTrail {
  padding: 0rem 1rem 0rem 1rem;
}

.HomePageJoinButton {
  font-size: 1.2rem;
  border-color: transparent;
  border-radius: 0.5rem;
  padding: 0.75rem 2.5rem 0.75rem 2.5rem;
  background-color: white;
  cursor: pointer;
}
