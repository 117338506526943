.SignUpPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  flex-direction: row;
}

.SignUpBox {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 400px;
}

.SignUpTitle {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.SignUpError {
  color: red;
  margin-bottom: 15px;
}

.SignUpInput {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 15px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.SignUpButton {
  background-color: #277ee5;
  color: white;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.SignUpButton:hover {
  background-color: #277ee5;
}

.SignUpTerms {
  font-size: 12px;
  margin-top: 20px;
  color: #666;
}

.SignUpTerms a {
  color: #277ee5;
  text-decoration: none;
}

.SignUpTerms a:hover {
  text-decoration: underline;
}

/* Temporary CSS changes for HomePage + SignUp page */

/* Container for the entire page */
.SignUpContainer {
  display: flex;
  height: 100vh;
  flex-direction: row;
  background-color: #f5f3ef;
}

/* Left side styling */
.SignUpLeftContainer {
  flex: 1;
  height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

/* .logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.logo {
  height: 40px;
  margin-right: 0.5rem;
} */

.SignUpMainHeading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
}

.SignUpBox {
  width: 100%;
  max-width: 350px;
  text-align: center;
  background-color: #f7f6f4;
  padding: 2rem;
  border-radius: 8px;
}

.SignUpButton {
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.SignUpEmail {
  background-color: #f4b400;
  color: #fff;
}

.SignUpInput {
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.SignUpTermsText {
  font-size: 0.75rem;
  color: #777;
  margin-top: 1rem;
}

/* Right side styling */
.SignUpRightContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  /* align-items: center;
  position: relative; */
}

.SignUpRightContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SignUpImageContainer {
  position: relative;
  margin-bottom: 4rem;
  margin-left: -100%;
}

.SignUpImageContainer2 {
  position: relative;
  margin-left: -16%;
  /* margin-left: -100%; */
}

.SignUpFloatingImage {
  width: 200px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.SignUpFloatingImage2 {
  width: 370px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.SignUpCaption {
  position: absolute;
  top: -26%;
  left: 59%;
  padding: 13px 10px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  color: #555;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* width: 100%; */
  width: 310px;
  margin: 0;
}

.SignUpCaption2 {
  position: absolute;
  top: 85%;
  left: 46%;
  padding: 13px 10px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  color: #555;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* width: 100%; */
  width: 310px;
  margin: 0;
}
