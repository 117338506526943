.GenericSideBar {
  width: 20rem; /* Full width when expanded */
  opacity: 1;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f8;
  background: #e5e7f1;
  padding: 1rem;
  height: calc(100vh - 2rem);
  overflow-y: auto;
}

.GenericSideBar.collapsed {
  width: 0; /* Sidebar shrinks to 0 when collapsed */
  padding: 0;
  opacity: 0; /* Fades out */
  overflow: hidden; /* Hides content when collapsed */
}

.GenericSideBar.transition {
  transition:
    height 0.5s ease,
    width 0.5s ease,
    color 0.5s ease,
    background-color 0.5s ease;
}

.GenericSideBar.rounded {
  border-radius: 0 25px 25px 0;
}

.GenericSideBarToggleIcon {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
}
