.gearIcon,
.logo {
  font-size: 26px;
}

.gearIcon {
  position: relative;
  cursor: pointer;
}

.defaultScreen {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.defaultScreenName {
  font-size: 24px;
  font-family: 'Roboto';
}
.defaultScreenIcon {
  font-size: 45px;
  font-family: 'Roboto';
}

.defaultWidth {
  width: 70%;
  margin: auto;
}

.Spacer {
  padding: 1rem;
}

.hidden {
  display: none !important;
}
