.LargeInputField {
  flex: 1;
  padding: 10px 22px;
  font-size: 1rem;
  border: 1px solid #ccc;
  resize: vertical; /* Allow vertical resizing */
  overflow-y: auto;
  border-radius: 50px;
  background: #e4e5ef;
}

.LargeInputField::placeholder {
  color: #9d9d9d;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}
