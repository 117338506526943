.search-input {
  padding: 10px 12px 10px 33px;
  max-width: 731px;
  width: 100%;
  position: relative;
  border-radius: 15px;
  border: 1px solid #c9c9c9;
  background: #fff;
  color: #9d9d9d;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.48px;
}

.search-icon {
  position: absolute;
  top: 50%;
  color: black;
  left: 10px;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 16px;
}

.historySearch-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 128px;
  max-width: 780px;
  margin: 128px auto 0;
}

.historySearch-container form {
  position: relative;
}

.chat-no {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin: 22px 0 22px 15px;
  text-align: left;
}

.chat-message {
  border-radius: 15px;
  background: #e5e7f1;
  padding: 8px 18px;
  margin-bottom: 12px;
}

.chat-message h4 {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  margin-bottom: 6px;
}

.chat-message p {
  color: #141414;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
}

.chat-list {
  width: 780px;
}
