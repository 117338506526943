/* Table container */
.TranscriptTable {
  border-collapse: separate;
  border-spacing: 0.5rem 0.25rem;
  flex: 1;
  font-family: 'Montserrat', sans-serif;
  width: 90%;
}

/* Restrict table body height */
.TranscriptTable tbody {
  display: block; /* Make tbody scrollable */
  max-height: 35rem;
  overflow-y: scroll;
  overflow-x: scroll;
}

/* Adjust column alignment since tbody is block */
.TranscriptTable thead {
  display: table; /* Retain table layout for rows */
  width: 100%; /* Ensure rows take full width */
  table-layout: fixed; /* Prevent columns from shifting */
}

.TranscriptTable tbody tr {
  display: table; /* Retain table layout for rows */
  width: 100%; /* Ensure rows take full width */
  table-layout: fixed; /* Prevent columns from shifting */
}

/* Transcript ID column: adjust width */

.TranscriptTable tbody td:first-child {
  width: 5rem; /* Adjust as needed to fit IDs */
}

.TranscriptTable thead th:first-child {
  width: 5rem; /* Adjust as needed to fit IDs */
}

/* Actions column: set a fixed width */
.TranscriptTable tbody td:last-child {
  width: 10rem; /* Adjust as needed */
}

.TranscriptTable thead th:last-child {
  width: 10rem;
}

.TranscriptTable thead th {
  background-color: #87cefa;
  padding: 0.5rem;
  text-align: center;
  border-right: 1px solid #ddd;
}

.TranscriptTable thead th:last-child {
  border-right: none;
}

.TranscriptTable tbody td {
  background-color: #f2f2f2;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  overflow: hidden;
}

.TranscriptTable tbody td:last-child {
  border-right: none;
}

/* Hover effect for rows */
.TranscriptTable tbody tr:hover {
  background-color: #f9f9f9;
}

/* Action buttons container */
.TranscriptTable tbody td div {
  display: flex;
  justify-content: center;
}

/* Button styles */
.TranscriptTable tbody td div button {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 1rem;
  cursor: pointer;
  width: fit-content;
  padding: 0.25rem 1.5rem 0.25rem 1.5rem;
  color: white;
}

.TranscriptTable tbody td div button.TranscriptTableEdit {
  background-color: #2196f3;
}

.TranscriptTable tbody td div button.TranscriptTableDelete {
  background-color: #f44336;
}

.TranscriptTable tbody td div.TranscriptTableActionsRowItem {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically in the cell */
  align-items: center; /* Center items horizontally in the cell */
  gap: 0.25rem;
}
