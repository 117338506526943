.SidePanelScaffoldContainer {
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.SidePanelScaffoldSidePanel.collapsed {
  width: 0; /* Sidebar shrinks to 0 when collapsed */
  opacity: 0; /* Fades out */
  overflow: hidden; /* Hides content when collapsed */
}

.SidePanelScaffoldPageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  transition: margin-left 0.3s ease-in-out;
  overflow-y: scroll;
}

.SidePanelScaffoldPageTopBarContainer {
  align-items: center;
  justify-content: center;
}

.SidePanelScaffoldToggleButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  color: #000;
}
