.FeedBackFormContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.FeedBackFormRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
}

.FeedBackFormCenteredRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FeedBackFormLabel {
  display: flex;
  align-items: left;
  justify-content: left;
  padding-right: 0.5rem;
}

.FeedBackFormSpacer {
  padding: 0.5rem;
}
